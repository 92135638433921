
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Redirige a la URL externa
    window.location.href = "https://grupodanigarcia.com/tienda/";
  }, []);

  return <></>;
}

export default App;
